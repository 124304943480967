<template>
  <div class="page">
      <van-nav-bar
      class="backBox"
      title="开具发票"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <van-notice-bar left-icon="info-o" text="抬头信息仅用于开具发票，请勿用于转账等其他用途，谨防受骗！" />
    <div class="billBox">
      <div class="list_wrap">
        <div class="billBoxItem">
          <div class="title" style="margin-top: 0">发票信息</div>
          <div class="section_2">
            <div class="billTitInfo">
              <div class="itemBox">
                <div class="image-text_6">
                  <div class="text-group_11">
                    <span class="text_9">抬头类型</span>
                    <van-radio-group
                      class="radioGroup"
                      @change="radioChange"
                      v-model="companyOrPersonal"
                    >
                      <label
                        class="weui-cell weui-check__label radioLabel"
                        v-for="item in items"
                        :key="item.value"
                      >
                        <div class="weui-cell__hd">
                          <van-radio :name="item.value" :label="item.value" />
                        </div>
                        <div class="weui-cell__bd">{{ item.name }}</div>
                      </label>
                    </van-radio-group>
                  </div>
                </div>
              </div>
              <div class="lineline"></div>
              <div class="itemBox">
                <div class="image-text_6">
                  <div class="text-group_11">
                    <span class="text_9">发票抬头</span>
                    <!-- <van-cell-group> -->
                    <van-field
                      v-model="form.companyName"
                      label=""
                      placeholder="发票抬头（最多50字）"
                     maxlength="50"

                        autosize
                      @blur="formBlur('companyName')"
                      :error-message="formError.companyName"

                      clearable
                    />
                    <!-- </van-cell-group> -->
                    <!-- <div class="weui-cell weui-cell_input">
                    <label for="">
                      <input
                        class="weui-input"
                        placeholder="企业名称（最多50字）"
                      />
                    </label>
                  </div> -->
                  </div>
                </div>
              </div>
              <div class="lineline" v-if="companyOrPersonal == 0"></div>
              <div class="itemBox" v-if="companyOrPersonal == 0">
                <div class="image-text_6">
                  <div class="text-group_11">
                    <span class="text_9">纳税人识别号</span>
                    <van-field
                      v-model="form.taxNumber"
                      label=""
                      placeholder="请输入纳税人识别号"
                      maxlength="20"
                      @blur="formBlur('taxNumber')"
                      :error-message="formError.taxNumber"
                      clearable
                    />
                    <!-- <div class="weui-cell weui-cell_input">
                    <label for="">
                      <input
                        class="weui-input"
                        placeholder="请输入纳税人识别号"
                      />
                    </label>
                  </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="billBoxItem">
          <van-collapse
            class="vanCollapseWrap"
            v-model="activeNames"
            @change="onChange"
          >
            <van-collapse-item
              class=""
              title-class="custom-collapse-item"
              title="更多信息"
              :label="'共5项,已填写' + haveWriteDone + '项'"
              name="1"
            >
              <div class="section_2">
                <div class="billTitInfo">
                  <div class="itemBox">
                    <div class="image-text_6">
                      <div class="text-group_11">
                        <span class="text_9">备注说明</span>
                        <van-field
                          v-model="form.comment"
                          label=""
                          placeholder="备注内容将展示在发票备注栏"
                          maxlength="120"
                          clearable
                          @blur="moreBlur($event)"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="lineline"></div>
                  <div class="itemBox">
                    <div class="image-text_6">
                      <div class="text-group_11">
                        <span class="text_9">地址</span>
                        <van-field
                          v-model="form.attr"
                          label=""
                          placeholder="用于登记准备的购票方信息"
                          maxlength="80"
                          clearable
                          @blur="moreBlur"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="lineline"></div>
                  <div class="itemBox">
                    <div class="image-text_6">
                      <div class="text-group_11">
                        <span class="text_9">电话</span>
                        <van-field
                          v-model="form.phone"
                          label=""
                          placeholder="用于登记准备的购票方信息"
                          maxlength="20"
                          clearable
                          @blur="moreBlur"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="lineline"></div>
                  <div class="itemBox">
                    <div class="image-text_6">
                      <div class="text-group_11">
                        <span class="text_9">开户行名称</span>
                        <van-field
                          v-model="form.bankName"
                          label=""
                          placeholder="用于登记准备的购票方信息"
                           maxlength="40"
                           clearable
                          @blur="moreBlur"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="lineline"></div>
                  <div class="itemBox">
                    <div class="image-text_6">
                      <div class="text-group_11">
                        <span class="text_9">开户行账号</span>
                        <van-field
                          v-model="form.bankNum"
                          label=""
                          placeholder="用于登记准备的购票方信息"
                          @blur="moreBlur(form.bankNum)"
                          data-index="3"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </van-collapse-item>
          </van-collapse>
        </div>

        <div class="billBoxItem billBoxItemMoney">
          <div class="section_2">
            <div class="acceptBillInfo">
              <div class="itemBox">
                <div class="image-text_6">
                  <div class="text-group_11">
                    <span class="text_9">总金额</span>

                    <span class="text_9">{{ money.toFixed(2) }}元</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="billBoxItem">
          <div class="title">接收方式</div>
          <div class="section_2">
            <div class="acceptBillInfo">
              <div class="itemBox">
                <div class="image-text_6">
                  <div class="text-group_11">
                    <span class="text_9">电子邮箱</span>
                    <van-field
                      v-model="form.email"
                      label=""
                      placeholder="输入收票人电子邮箱"
                      maxlength="50"
                      @blur="formBlur('email')"
                      :error-message="formError.email"
                      clearable
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn_wrapper">
        <van-button
          class="pay_button"
          type="info"
          color="#4470FC"
          @click="onClickRecharge"
          >确认</van-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import * as common from '../../../api/common'
import * as constant from '../../../api/constant'
import { mapState } from 'vuex'
export default {
  props: ['type', 'num', 'applyDirec'],
  data () {
    return {
      money: 0,
      typeFlag: null,
      companyOrPersonal: '0', // 默认公司0，个人是1
      activeNames: ['1'],
      items: [
        { value: '0', name: '企业' },
        { value: '1', name: '个人' }
      ],
      haveWrite: '共5项，已填写2项',
      haveWriteDone: 0,
      moreList: [],
      form: {
        companyName: '',
        taxNumber: '',
        comment: '',
        attr: '',
        phone: '',
        bankName: '',
        bankNum: '',
        email: ''
      },
      formError: {
        companyName: '',
        taxNumber: '',
        email: ''
      }
    }
  },
  mounted () {
    this.typeFlag = this.type // true为月卡开票  false为停车开票
    this.money = Number(this.num)
    this.apply_direc = Number(this.applyDirec) === 1
    console.log('开票类型停车还是月卡', this.typeFlag)
    console.log('开票金额', this.money)
    // 订单列表进来0 false   还是支付完成进来1 true
    console.log('订单列表进来还是支付完成进来', this.apply_direc)
  },
  computed: {
    ...mapState({
      applyBillInfo: state => state.applyBillInfo.applyBillInfo
    })

  },
  methods: {
    onChange () {

    },
    formBlur (field) {
      if (field === 'companyName') {
        this.checkCompanyName()
      } else if (field === 'taxNumber') {
        this.checkTaxNumber()
      } else if (field === 'email') {
        this.checkEmail()
      }
    },
    checkAll () {
      const bol1 = this.checkCompanyName()
      const bol3 = this.checkEmail()
      let bol2 = true
      if (Number(this.companyOrPersonal) === 0) {
        bol2 = this.checkTaxNumber()
      }
      return bol1 && bol2 && bol3
    },
    checkCompanyName () {
      const form = this.form
      if (form.companyName) {
        if (!/^[^<>]+$/.test(form.companyName)) {
          this.formError.companyName = '发票抬头格式错误'
        } else {
          this.formError.companyName = ''
          return true
        }
      } else {
        this.formError.companyName = '发票抬头不能为空'
      }
    },
    checkTaxNumber () {
      const form = this.form
      // if (form.naturalPersonFlag === 1) {
      if (form.taxNumber) {
        if (!/^[A-Z0-9]{15}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/.test(form.taxNumber)) {
          this.formError.taxNumber = '税号格式错误'
        } else {
          this.formError.taxNumber = ''
          return true
        }
      } else {
        this.formError.taxNumber = '税号不能为空'
      }
      // } else {
      // return true
      // }
    },
    checkEmail () {
      const form = this.form
      if (form.email) {
        if (!/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$/.test(form.email)) {
          this.formError.email = '收票人邮箱格式错误'
        } else {
          this.formError.email = ''
          return true
        }
      } else {
        this.formError.email = '收票人邮箱不能为空'
      }
    },
    // 提交开发票
    onClickRecharge () {
      // if (this.skipParamError) {
      //   return
      // }
      console.log('onClickRecharge ing')
      if (!this.checkAll()) {
        return
      }

      const that = this

      that.isPress = true
      this.$toast.loading({
        forbidClick: true,
        message: '请稍候...'
        // duration: 10000
      })
      const path = that.applyDirec ? '/pub/pay.bill.apply_direct' : '/pub/pay.bill.apply'
      try {
        axios({
          url: common.cloudPayUrl + path,
          method: 'POST',
          data: {
            sid: that.applyBillInfo.sid,
            fee_order_sn: that.applyBillInfo.fee_order_sn,
            vh_p_number: that.applyBillInfo.vh_p_number,
            parkName: that.applyBillInfo.parkName,

            buyerName: that.form.companyName,
            buyerTaxpayerNum: Number(that.companyOrPersonal) === 0 ? this.form.taxNumber : null,
            naturalPersonFlag: that.companyOrPersonal,
            takerEmail: that.form.email,
            buyerAddress: that.form.attr,
            buyerTel: that.form.phone,
            buyerBankName: that.form.bankName,
            buyerBankAccount: that.form.bankNum,
            remark: that.form.comment
          },
          header: {
            'content-type': 'application/json' // 默认值
          }
        }).then(response => {
          that.$toast.clear()
          const res = response.data
          if (res.code === common.resCode.OK) {
            that.$toast({
              type: 'success',
              message: '提交成功',
              duration: 1800,
              forbidClick: true
            })
            setTimeout(function () {
              that.onClickLeft()
            }, 1900)
          } else {
            that.$toast.fail(res.msg || '提交失败')
            that.isPress = false
          }
        }).catch((e) => {
          that.$toast.clear()
          that.$toast.fail(e || '提交失败')
          that.isPress = false
        })
      } catch (e) {
        console.log('error', e)
      }
    },
    moreBlur (e) {
      console.log('失去焦点', e)
      let num = 0
      if (this.form.comment) {
        num = num + 1
      }
      if (this.form.attr) {
        num = num + 1
      }
      if (this.form.phone) {
        num = num + 1
      }
      if (this.form.bankName) {
        num = num + 1
      }
      if (this.form.bankNum) {
        num = num + 1
      }
      this.haveWriteDone = num
    },
    radioChange (value) {
      console.log('选中的值是：', value)
      // 1企业  2个人
    },
    onClickLeft () {
      this.$router.go(-1)
    }

  },
  beforeDestroy () {
    this.$store.commit(constant.keyApplyBillInfoVuex, null)
  }
}
</script>
<style lang="scss" scoped>
.page {
  height: 100%;
}
.lineline {
  height: 0.005rem;
  background-color: #e2e2e2;
  margin-top: 0.07rem;
  margin-bottom: 0.07rem;
}
.billBox {
  padding: 0.16rem 0.16rem 0;
  .billBoxItemMoney {
    margin-top: 0.16rem;
  }
  .billBoxItem {
    .title {
      margin-top: 0.16rem;
      margin-bottom: 0.16rem;
      font-size: 0.16rem;
      text-align: left;
    }

    // >>> .more_box {
    //    .van-cell__title {
    //     span {
    //       font-size: 0.16rem !important;
    //     }
    //   }
    // }
    .section_2 {
      background-color: #fff;
      // width: 3.43rem;
      align-self: center;
      /* margin-top: 26rpx; */
      display: flex;
      flex-direction: column;
      padding: 0.08rem 0.01rem 0.11rem 0;
      .itemBox {
        width: 3.25rem;
        flex-direction: column;
        display: flex;
        justify-content: space-between;
        margin: 0.04rem 0.07rem 0 0.1rem;
        .image-text_6 {
          display: flex;
          /* width: 258rpx; */
          flex-direction: row;

          justify-content: space-between;
          .text-group_11 {
            display: flex;
            width: 100%;
            align-items: center;
            /* flex-direction: column; */
            .weui-cell {
              // margin-left: 0.3rem;
              .weui-input {
                color: #666;
                font-size: 0.14rem;
              }
            }
            .text_9 {
              // display: flex;
              width: 0.28rem;
              overflow-wrap: break-word;
              color: #000;
              font-size: 0.14rem;
              font-family: PingFangSC-Regular;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 0.24rem;
              margin: 0 0.65rem 0 0;
            }
            /deep/ .van-field {
              // padding: 0.05rem 0.08rem;
              padding: 0;
              .van-field__control {
                font-size: 0.14rem;
                color: #666;
              }
            }
            .radioGroup {
              width: 50%;
              display: flex;
              justify-content: space-between;
              .radioLabel {
                display: flex;
                align-items: center;
              }
            }
            .weui-cell__bd {
              font-size: 0.16rem;
            }
          }
        }
      }
    }
  }

  .btn_wrapper {
    display: flex;
    position: sticky;
    bottom: 0;
    z-index: 99;
    margin-top: 0.16rem;
    margin-bottom: 0.16rem;
  }
  .pay_button {
    background-color: #4797ea;
    width: 100%;
    border-radius: 0.04rem;
    margin-bottom: 0.16rem;
  }

  /deep/ .custom-collapse-item {
    display: flex;
    justify-content: space-between;
    font-size: 0.16rem;
  }

  /deep/ .van-collapse-item {
    .van-collapse-item__title {
      padding: 0.16rem 0;
      background-color: #f8f7fc;
      // margin-top: 0.16rem;
    }
    .van-collapse-item__wrapper {
      .van-collapse-item__content {
        padding: 0.08rem 0;
      }
      .section_2 {
        padding: 0;
      }
    }
  }
}
</style>
